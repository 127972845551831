@import '../../assets/scss/components/_variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.fix {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .container-option {
        background-color: white;
        padding: 56px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 40px;
        align-items: center;
        @include media-breakpoint-down(md) {
            gap: 20px;
            padding: 16px;
        }

        h2 {
            margin: 0px;
            padding: 0px;
            color: #000;
            text-align: center;

            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
        }
        .image-products {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 40px;
            @include media-breakpoint-down(md) {
                gap: 20px;
                flex-direction: column;
            }
            img {
                cursor: pointer;
                @include media-breakpoint-down(md) {
                    width: 296px;
                    height: 196px;
                }
                @include media-breakpoint-up(md) {
                    width: 354px;
                    height: 235px;
                }
                @include media-breakpoint-up(lg) {
                    width: 364px;
                    height: 235px;
                }
            }
            .container {
                position: relative;
            }
            .text-overlay {
                position: absolute;
                top: 80%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                color: white;
                z-index: 1; /* Ensure text is above the image */
                font-size: 16px;

                font-weight: 700;
                width: 100%;
                cursor: pointer;
                @include media-breakpoint-down(md) {
                    font-size: 8px;
                }
            }
        }
    }
}
