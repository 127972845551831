.history {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 56px;
    gap: 20px;
    flex: auto;
    @media (max-width: 768px) {
        padding: 16px 24px;
        gap: 24px;
    }
    .lists-history {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .card-history {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            padding: 16px;
            border-bottom: dashed 0.5px rgba(0, 0, 0, 0.2);
            .detail {
                display: flex;
                gap: 16px;
                .ant-image img {
                    width: 200px;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                    @media (max-width: 768px) {
                        width: 150px;

                        // display: none;
                    }
                }
                .text {
                    .price {
                        margin: 4px 0 16px 0;
                    }
                }
                .status-container {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: rgba(0, 0, 0, 0.4);
                    padding: 0 0 8px 0;
                }
                @media (max-width: 640px) {
                    align-items: center;
                    flex-direction: column;
                }
            }
            .button-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 8px;
                min-width: 220px;
                button {
                    height: 50px;
                    border-radius: 20px;
                }
            }
            @media (max-width: 768px) {
                flex-direction: column;
            }
        }
        .pagination {
            display: flex;
            justify-content: flex-end;
        }
        @media (max-width: 768px) {
            gap: 40px;
        }
    }
}
